import React from "react"
import { Link } from "gatsby"
import { useDispatch } from "react-redux"
import { attempted_mcqs_update } from "../redux/attempted_mcqs_slice"




const McqsCard = ({id, title, slug, options, correct, is_given_answer_true, given_answer }) => {

  const dispatch = useDispatch()


  const updater = (x) => {
    dispatch(attempted_mcqs_update(x))
  }


  return (
    <div className="my-8 p-3 border border-black border-solid">
      <div className="bg-blue-400 p-4 shadow-sm">
        <Link to={slug}>{title}</Link>
      </div>
      <ul>
        {options.map(option => (
          <li
            className={`shadow-lg m-2 shadow-gray-300 p-2 bg-opacity-30 bg-gray-600 cursor-pointer hover:bg-gray-800 ${!is_given_answer_true && option === given_answer && `bg-red-600`} ${is_given_answer_true != undefined && option == correct && `bg-green-600`} `}
            key={option + Math.random()}
            onClick={(e)=> updater({id, given_answer : e.target.textContent, is_given_answer_true : e.target.textContent === correct})}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default McqsCard
