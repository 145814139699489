import React from "react"

const HomePageCategoryCard = ({ title }) => {
  return (
    <div className=" bg-black bg-opacity-40 m-4 z-25 text-brown-900 text-4xl text-center px-5 shadow shadow-2x shadow-gray-900 ">
      {title}
    </div>
  )
}

export default HomePageCategoryCard
