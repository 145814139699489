import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import HomePageCategoryCard from "../components/homePageComponents/homePageCategoryCard"
import McqsCard from "../components/McqsCard"
import { useSelector } from "react-redux"
import objectDataCombiner from "../utils/objectDataCombiner"
import _ from "lodash";

const Index = ({ data }) => {

  const attempted_mcqs = useSelector((state) => state.attempted_mcqs.ids)
  objectDataCombiner(attempted_mcqs, data.mcq.edges)


  ChartJS.register(ArcElement, Tooltip, Legend);


  const number_of_given_answer_true =  _.size( _.filter(attempted_mcqs, ['is_given_answer_true', true]))
  const number_of_given_answer_false = _.size( _.filter(attempted_mcqs, ['is_given_answer_true', false]))

  const graph_data =  {
    labels: ['Correct Answer', 'Wrong Answer'],
    datasets: [
      {
        label: '# of Votes',
        data: 
          [
            number_of_given_answer_true,
            number_of_given_answer_false
          ],
        backgroundColor: [
          'rgba(38, 166, 91, 0.5)',
          'rgba(255, 76, 48, 0.5)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
        hoverOffset: 10,
        // radius: '70%',
      },
    ],
  };

  const graph_options = {
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <>
      <div className=" flex justify-center flex-wrap">
        <div>
          <div className="my-8 p-8 max-h-52 border border-black border-solid">
           
           
            {
              number_of_given_answer_true ||
              number_of_given_answer_false ?
              

    
              <Doughnut 
                data={graph_data} 
                options={graph_options}
              />
              :
              <p>Attemp MCQs to See your Progress Here</p>
            }

          </div>

          {data.mcq.edges.map(edge => (
            <McqsCard
            key={edge.node.id}
              id={edge.node.id}
              title={edge.node.frontmatter.title}
              options={edge.node.frontmatter.options}
              slug={edge.node.frontmatter.slug}
              correct={edge.node.frontmatter.correct}
              is_given_answer_true={edge.node.is_given_answer_true}
              given_answer={edge.node.given_answer}
            />
          ))}
        </div>

        <div className="">
          {data.category.edges.map(edge => (
            <Link
              key={edge.node.frontmatter.slug}
              to={edge.node.frontmatter.slug}
            >
              <HomePageCategoryCard title={edge.node.frontmatter.title} />
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export const HomePagequery = graphql`
  {
    category: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "category" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            title
          }
        }
      }
    }
    mcq: allMarkdownRemark(filter: { frontmatter: { type: { eq: "mcq" } } }) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            title
            options
            correct
          }
        }
      }
    }
  }
`

export default Index
