const objectDataCombiner = (first_array, second_array) => {

    first_array.map(x => {
        
        //Find index of specific object using findIndex method.    
        const objIndex = second_array.findIndex((obj => obj.node.id == x.id));

        
        second_array[objIndex].node.is_given_answer_true = x.is_given_answer_true
        second_array[objIndex].node.given_answer = x.given_answer


      return second_array
    })
}


export default objectDataCombiner